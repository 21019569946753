import moment from 'moment';
import { getCurrentScope } from '@sentry/react';
import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import 'moment/dist/locale/ja';
import setHtmlTagLang from './utilities/setHtmlTagLang';

/**
 * Sets "locale" tag
 *
 * @param {string} lang en, ja
 * @return {void}
 */
const setLocaleScope = (lang: string) => {
    getCurrentScope().setTag('locale', lang);
};

// When the language is changed, update the HTML Lang tag and update Moment. Unfortunately, any translations
// which were determined before this callback runs will be 'stuck' in the wrong language (until the user refereshes).
// This is kind of unavoidable unless we completely restructure the app. Damn.
i18next.on('languageChanged', (lang: string) => {
    setHtmlTagLang(lang);
    moment.locale(lang);
    setLocaleScope(lang);
});

// Set the custom moment format for supported languages.
const opts = { calendar: { sameElse: 'YYYY-MM-DD HH:mm:ss' } };
moment.updateLocale('en', opts);
moment.updateLocale('ja', opts);

export * from './constants.js';
export * from './helpers/index';
export { default as I18nProvider } from './components/I18nProvider.jsx';
export { Trans } from 'react-i18next';
export { default as TransVar } from './components/TransVar.jsx';
export { default as __ } from './utilities/__';
export { default as addressSelfByName } from './utilities/addressSelfByName.js';
export { default as familyNameFirst } from './utilities/familyNameFirst.js';
export { default as getLanguage } from './utilities/getLanguage.js';
export { i18next };

// Initialize i18next
export const init = () =>
    i18next
        .use(LngDetector)
        .use(backend)
        .init({
            debug: false,
            detection: {
                order: ['querystring', 'localStorage', 'navigator'],
                lookupQuerystring: 'lang',
                lookupLocalStorage: 'digimaLang',
            },
            ns: [
                'common',
                'attributes',
                'andpad',
                'buttons',
                'countries',
                'contacts',
                'email-inbox',
                'integrations-andpad',
                'file',
                'lead-acquisitions',
                'line',
                'mappers',
                'oauth',
                'open-api',
                'placeholders',
                'roles',
                'sms',
                'timeline',
                'timezones',
                'titles',
                'users',
                'validations',
                'workflows',
                'product-discovery',
            ],
            defaultNS: 'common',
            nsSeparator: '::',
            keySeparator: false,
            fallbackLng: false, // Disable fallback lang as we use keys.
            fallbackNS: false,
            returnNull: false,
            interpolation: {
                escapeValue: false, // React will do the escaping for us.
            },
            react: {
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ['b', 'br', 'strong'],
            },
        });

export { default as makeTranslationMap } from './utilities/makeTranslationMap';
